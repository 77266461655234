'use client';

import Link from 'next/link';

import css from '@features/error/404.module.css';
import useECText from '@features/shared/ectext';

type ErrorProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function Page({ error }: ErrorProps) {
  const ECText = useECText();

  const message =
    error?.message ?? ECText.getText(78741, '404: Unexpected error');

  return (
    <div className={css.container}>
      <h1>{ECText.getText(78742, 'Problems loading the page')}</h1>
      <p>{message}</p>
      <p>
        <Link href="/tenant" replace={true}>
          {ECText.getText(-1, 'Go to select tenant page')}
        </Link>
      </p>
    </div>
  );
}
